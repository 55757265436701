import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import T_ABOUT_QUESTIONS from "../../components/t_about_questions"

const AboutLotionMoisturizerPage = () => (
  <Layout sectionClasses={"t-about-lm"} dataNamespace={"about_lotion_moisturizer"}>
    <SEO title="About Lotion Moisturizer" />
    <div className="t-about-lm__bg">
        <picture>
          <source media="(max-width:959px)" srcSet={"/images/about/lm/sp/fv.jpg"} />
          <img className="js-fitimg" src={"/images/about/lm/fv.jpg"} alt="" />
        </picture>
      <div className="t-about-lm__bg__absolute ">
        <div className="t-about-lm__bg__absolute__center">
            <h1>LOTION&<br className="pc-hidden"/>MOISTURIZER</h1>
            <h3>BASE SKIN CARE SET</h3>
        </div>
      </div>
    </div>

    <div className="t-about-lm__basecare">
      <div className="t-about-lm__basecare__left">
        <h2>BASE CARE</h2>
        <h3>LOTION & MOISTURIZER</h3>
        <p className="t-about-lm__basecare__left__txt txt-desc">もうアイテム選びに悩まなくていい。<br />
          あなたのお肌の声からオーダーメイドしてお届けする<br />
          HOTARU PERSONALIZEDのローションと<br className="sp-hidden" />
          モイスチャライザー。<br />
          肌トラブルのきっかけとなる皮脂バランスと<br className="sp-hidden" />
          水分・NMFのバランスを同時に整え、<br />
          トラブルのない肌へ導きます。
        </p>
        <img className="pc-hidden t-about-lm__basecare__left__img" src={"/images/about/lm/02.jpg"} alt="" />
        <img className="pc-hidden t-about-lm__basecare__left__absolute" src={"/images/about/lm/01.png"} alt="" />
        <p className="txt-desc">オンライン診断の結果に基づき、<br />
          14万通りの中からあなたにぴったりの2本を選出。<span className="pc-hidden">&nbsp;</span><br className="sp-hidden" />
          忙しい毎日に、<span className="pc-hidden">&nbsp;</span>あなただけの2ステップを。<br />
          <br />
          あなた専用のスキンケアが、<br />
          あなたの毎日を彩ります。
        </p>
        <h4 className="absolute-txt rot-right sp-hidden">ABOUT BASE SKIN CARE SET</h4>
      </div>
      <div className="t-about-lm__basecare__right">
        <img className="sp-hidden t-about-lm__basecare__right__img" src={"/images/about/lm/02.jpg"} alt="" />
        <img className="sp-hidden t-about-lm__basecare__right__absolute" src={"/images/about/lm/01.png"} alt="" />
      </div>
    </div>

    <div className="t-about-lm__lotion">
      <div className="t-about-lm__lotion__block">
        <p className="t-about-lm__lotion__block__num-f sp-hidden">01</p>
        <img className="sp-hidden" src={"/images/about/lm/lotion.png"} alt="" />
        <div className="t-about-lm__lotion__block__right">
          <h2>LOTION</h2>
          <h3>BASE CARE</h3>
          <p className="t-about-lm__lotion__block__num-f pc-hidden">01</p>
          <p className="t-about-lm__lotion__block__num-b pc-hidden">01</p>
          <img className="pc-hidden" src={"/images/about/lm/lotion.png"} alt="" />
          <p className="txt-desc">あなたのお肌にうるおいを与える<br />
            ローション。<br className="sp-hidden" />
            年齢とともに減少してしまう<br className="sp-hidden" />
            肌内部の水分量を整え、<br className="sp-hidden" />
            あなたのなりたい<br className="sp-hidden" />
            理想のお肌へ導く化粧水。<br />
            <br />
            季節や環境の変化、<br />
            不規則な生活リズムなど、<br />
            様々なストレスにさらされているお肌を<br />
            潤いのバリアで優しく包み込みます。
          </p>
        </div>
      </div>
    </div>

    <div className="t-about-lm__moisturizer">
      <div className="t-about-lm__moisturizer__block">
        <p className="t-about-lm__moisturizer__block__num-b sp-hidden">02</p>
        <div className="t-about-lm__moisturizer__block__left">
          <h2>MOISTURIZER</h2>
          <h3>BASE CARE</h3>
          <p className="t-about-lm__moisturizer__block__num-b pc-hidden">02</p>
          <img className="pc-hidden" src={"/images/about/lm/moisturizer.png"} alt="" />
          <p className="txt-desc">うるおいに蓋をするモイスチャライザー<br />
            お肌のうるおいに蓋をして、水分量と皮脂量の<br className="sp-hidden" />
            バランスを保つ役割の乳液。<br />
            <br className="sp-hidden" />
            大切なことは、うるおいを持続させること。<br className="sp-hidden" />
            ふっくらとしたやわらかさに満たされた、<br className="sp-hidden" />
            瑞々しいお肌を見守ります。
          </p>
        </div>
        <img className="sp-hidden" src={"/images/about/lm/moisturizer.png"} alt="" />
      </div>
    </div>

    <div className="t-about-lm__point">
      <h3>BASE SKIN CARE SET</h3>
      <p className="t-about-lm__point__cat"><span className="t-about-lm__point__num">4</span><span className="t-about-lm__point__ttl">Point</span></p>
      <div className="t-about-lm__point__block">
        <img className="" src={"/images/about/lm/point_01.jpg"} alt="" />
        <div className="t-about-lm__point__block__inner">
          <p className="t-about-lm__point__block__inner__num">01</p>
          <p className="txt-desc">目指したのは、あなた好みのうるおい感</p>
          <hr />
          <p className="txt-desc">しっとりが好き。さっぱりが好き。<br />
            あなた好みの使用感で、極上のスキンケア体験を。<br className="sp-hidden" />
            そして仕上がる、あなたのお肌に触れてみて下さい。<br />
            あなたの肌の水分と油分状態を計測・解析し<br className="sp-hidden" />
            一人一人のなりたい肌のうるおい感を実現する<br className="sp-hidden" />
            ことを目指しました。
          </p>
        </div>
      </div>
      <div className="t-about-lm__point__block">
        <img className="" src={"/images/about/lm/point_02.jpg"} alt="" />
        <div className="t-about-lm__point__block__inner">
          <p className="t-about-lm__point__block__inner__num">02</p>
          <p className="txt-desc">未来のお肌をつくる<br className="pc-hidden" />“グロースファクター”配合</p>
          <hr />
          <p className="txt-desc">グロースファクターはもともとお肌にある成分で<br className="sp-hidden" />
            ハリ・ツヤ・弾力・うるおいを助けてくれています。肌の美しさを保つために欠かせない成分ですが、<br />
            加齢によって分泌量が減ってしまいます。<br />
            HOTARU PERSONALIZEDには肌を整える因子EGF、肌のEGFの働きを高める因子IGF-1の二つのグロースファクターを配合。<br />
            未来のあなたが、今よりもっと輝けるように。<br className="sp-hidden" />
            HOTARU PERSONALIZEDがお手伝いします。
          </p>
        </div>
      </div>
      <div className="t-about-lm__point__block">
        <img className="" src={"/images/about/lm/point_03.jpg"} alt="" />
        <div className="t-about-lm__point__block__inner">
          <p className="t-about-lm__point__block__inner__num">03</p>
          <p className="txt-desc">5種のセラミド・15種のアミノ酸配合</p>
          <hr />
          <p className="txt-desc">あなたの肌本来の力を引き出すために。<br />
            5種類のセラミドと15種類のアミノ酸を配合。<br className="sp-hidden" />
            健やかなうるおいに満ちた肌へ導きます。<br />
            <br />
            セラミドにより肌の土台である細胞間脂質を整え、<br className="sp-hidden" />
            アミノ酸により保湿機能をサポートことで、<br className="sp-hidden" />
            肌本来のうるおい力を高めることを目指しました。
          </p>
        </div>
      </div>
      <div className="t-about-lm__point__block">
        <img className="" src={"/images/about/lm/point_04.jpg"} alt="" />
        <div className="t-about-lm__point__block__inner">
          <p className="t-about-lm__point__block__inner__num">04</p>
          <p className="txt-desc">なりたいあなた・悩み別処方</p>
          <hr />
          <p className="txt-desc">お肌のお悩みは十人十色。<br />
            HOTARU PERSONALIZEDはあなたの肌の水分・油分状態を計測・解析し、理想のお肌と今のお悩みに合わせて本当に必要な成分を処方します。あなただけのスキンケアで、あなたの理想のお肌へ。
          </p>
        </div>
      </div>
      <div>
        <Link to={process.env.QUESTION_URL_INTEGRATED_FORM} className="button">ONLINE肌診断(無料)</Link>
      </div>
    </div>

    <div className="t-about-lm__desc">
      <img className="t-about-lm__desc__offer-img" src={"/images/about/lm/offer_lm.jpg"} />
      <div className="t-about-lm__desc__block">
        <h2>HOTARU PERSONALIZED<br />LOTION & MOISTURIZER</h2>
        <h3>ローション＆モイスチャライザー<br />（化粧水・乳液）</h3>
        <div className="t-about-lm__desc__block__label">
          <p className="left">４週間に<br />１回お届け(定期)</p>
          <p className="right"><span>初回</span><p><span className="value">2,960</span>円(税込)</p></p>
        </div>
        <ul>
          <li><span>通常価格：</span><span className="value">10,890円</span><span>(税込)</span></li>
          <li><span>2回目以降：</span><span className="value">9,758円</span><span>(税込)</span></li>
          <li><span>内容量：ローション(</span><span className="value">115g</span>)<span>・モイスチャライザー(</span><span className="value">80g</span><span>)朝晩のご使用で約1か月分相当</span></li>
          <li><span>初回のみ送料650円（沖縄・北海道・離島は800円）</span></li>
          <li><span>マイページに記載の次回出荷予定日の11日前（変更締切日）までに解約のお電話がない限り契約が続き、毎月自動で商品をお届けいたします。※土日祝その他休業日は前営業日。不良品や品違いの場合、交換・キャンセル可。</span></li>
          <li><span>ご使用方法は<a href="https://sparty.my.site.com/spartyshopfaq/s/article/HOTARU-product-4">こちら</a></span></li>
        </ul>
        <div>
          <Link to={process.env.QUESTION_URL_INTEGRATED_FORM} className="button">購入する</Link>
        </div>
      </div>
    </div>

      {/*
      <T_ABOUT_QUESTIONS />
      <Link class="p-pageBackBtn" to="/">
        <div class="c-grid">
          <div class="c-grid__row">
            <div class="c-grid__col">
              <span class="p-pageBackBtn__inner">BACK TO TOP</span>
            </div>
          </div>
        </div>
      </Link>
      */}
  </Layout>
)

export default AboutLotionMoisturizerPage
